import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Navbar = lazy(() => import('./components/navbar/navbar'));
const Homepage = lazy(() => import('./pages/home/home'));
const Contact = lazy(() => import('./pages/contact/contact'));
const ErrorPage = lazy(() => import('./pages/page404/page404'));

function App() {
  return (
    <>
      <Navbar />
      <Homepage />
      {/* <Router>
        <Suspense fallback={
          <div className="loading">
          </div>
        }>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </Router> */}
    </>
  );
}

export default App;
